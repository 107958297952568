
import { Options, Vue } from 'vue-class-component'
import api from '@/api'
import store from '../../store'

@Options({
  components: {}
})
export default class personnel extends Vue {
  table = [];
  loading = false

  created () {
    this.loading = true
    api.getInfoVerify(store.getters['company/get']).then((data:any) => {
      this.table = data.employees
    }).finally(() => {
      this.loading = false
    })
  }
}
